<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                            sm="12"
                        >
                            <h1>{{ i18nT(`Clients & Vendors`) }}</h1>
                            <div class="mb-1">
                                <span class="text-muted">{{ i18nT(`Showing`) }} {{ dataMeta.from }} {{ i18nT(`to`) }}
                                    {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
                                    {{ i18nT(`entries`) }}</span>
                            </div>
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                            sm="12"
                        >
                            <b-dropdown
                              v-if="iCan( 'vendors', 'write' )"
                              id="add-new-dropdown"
                              variant="primary"
                              class="float-right"
                              right
                          >
                            <template #button-content>
                              <feather-icon icon="PlusCircleIcon" />
                              {{ i18nT(`Add new`) }}
                            </template>

                            <b-dropdown-item>
                              <b-link :to="{ name: 'create-vendor'}">
                                <font-awesome-icon class="mr-25" :icon="['fas', 'user-plus']" />
                                {{ i18nT(`Add`) }}
                              </b-link>
                            </b-dropdown-item>

                            <b-dropdown-item
                                v-b-modal.modal-import-csv
                                >
                              <div class="text-primary">
                                <font-awesome-icon class="mr-25" :icon="['fas', 'file-import']" />
                                {{ i18nT(`Import`) }}
                              </div>
                            </b-dropdown-item>

                          </b-dropdown>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="12"
                            md="4"
                        >
                            <b-form-group :label="i18nT(`Vendor type`)">
                                <v-select
                                    v-model="vendorType"
                                    :options="vendorTypes"
                                    :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                    label="text"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div>
                <div>
                    <div class="row mx-2">
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :clearable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions"
                                class="per-page-selector d-inline-block"
                            />
                            <b-dropdown
                                v-if="selectedItems.length > 0"
                                class="d-inline ml-1"
                                size="md"
                                text="Actions"
                                variant="outline-secondary"
                            >
                                
                                <b-dropdown-item @click="onBulkDelete(data)">
                                    <feather-icon
                                        color="red"
                                        icon="TrashIcon"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{
                                            i18nT(`Delete`)
                                        }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- <b-dropdown
                              size="md"
                              class="d-inline ml-1"
                              variant="outline-secondary"
                            >
                              <template v-slot:button-content>
                                <feather-icon icon="ExternalLinkIcon" />&nbsp;&nbsp;{{
                                  i18nT(`Export`)
                                }}
                              </template>
                              <b-dropdown-item @click="onExportExcel()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{ i18nT(`Excel`) }}</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click="onExportCsv()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{ i18nT(`CSV`) }}</span>
                              </b-dropdown-item>
                            </b-dropdown> -->
                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div
                                        class="input-group input-group-merge"
                                        role="group"
                                    >
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model.lazy="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            class="d-inline-block"
                                            type="text"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                     <b-row>
                    <b-col cols="12" class="text-align-right">

                    </b-col>

                  </b-row>
                    <b-table
                        ref="refDocumentListTable"
                        v-model="currentItems"
                        :empty-html="emptyHtml"
                        :fields="computedColumns"
                        :items="fetchDocuments"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        primary-key="id"
                        responsive
                        show-empty
                        @refreshed="onTableRefresh"
                    >
                        <template v-slot:head(checkbox)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                v-model="selectAllStatus"
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectedItems"
                                :value="data.item.Id"
                                class="pl-0"
                            />
                        </template>

                        <template #cell(name)="data">
                            <h5>
                                <b-link
                                    v-if="!data.item.IsSystem"
                                    :to="{
                                        name: 'edit-vendor',
                                        params: { id: data.item.Id },
                                      }"
                                >
                                    {{ data.item.Label }}
                                </b-link>
                                <span v-else class="text-muted"
                                      v-b-tooltip="i18nT(`This is a default system vendor and cannot be edited`)"
                                >{{ data.item.Label }}</span>
                            </h5>
                        </template>

                        <template #cell(type)="data">
                            <h5>{{ getTypeName(data.item.Type) }}</h5>
                        </template>

                        <template #cell(vat)="data">
                            <h5>{{ data.item.VatNumber }}</h5>
                        </template>

                        <template #cell(address)="data">
                            <h5>{{ data.item.Address }}</h5>
                        </template>

                        <template #cell(representative)="data">
                            <h5>{{ data.item.LegalRepresentative }}</h5>
                        </template>

                        <template #cell(status)="data">
                              <span
                                  v-if="data.item.PublicationStatus == 'ACTIVE'"
                                  class="badge badge-light-success badge-pill"
                              >{{ i18nT(`Active`) }}</span>
                            <span
                                v-else
                                class="badge badge-light-secondary badge-pill"
                            >{{ i18nT(`Inactive`) }}</span>
                        </template>

                        <template

                            #cell(action)="data">
                            <b-dropdown
                                :right="$store.state.appConfig.isRTL"
                                no-caret
                                toggle-class="p-0"
                                variant="link"
                                v-if="iCan('vendors', 'write') && !data.item.IsSystem"
                            >
                                <template #button-content>
                                    <feather-icon
                                        class="align-middle text-body"
                                        icon="MoreVerticalIcon"
                                        size="16"
                                    />
                                </template>
                                <b-dropdown-item
                                    :to="{
                                        name: 'edit-vendor',
                                        params: { id: data.item.Id },
                                      }">
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    @click="onDelete(data)">
                                    <feather-icon
                                        color="red"
                                        icon="TrashIcon"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{
                                            i18nT(`Delete`)
                                        }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                </div>

                <div class="mx-1 mb-2">
                    <b-row>
                        <b-col
                            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
                            cols="12"
                            sm="6"
                        >
                            <b-form-checkbox
                                v-model="selectAllStatus"
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">{{ i18nT(`Showing`) }} {{ dataMeta.from }} {{ i18nT(`to`) }}
                {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
                {{ i18nT(`entries`) }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
                            cols="12"
                            sm="6"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :per-page="perPage"
                                :total-rows="totalDocuments"
                                class="mb-0 mt-1 mt-sm-0"
                                first-number
                                last-number
                                next-class="next-item"
                                prev-class="prev-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card>

        <!-- MODAL -->
        <b-modal
            id="modal-vendor"
            no-close-on-backdrop
            ok-only
            size="lg"
            title="Vendor Info"
        >
            <vendor-view :data="selectedVendor"/>
        </b-modal>
      <!-- MODAL IMPORT CSV -->
      <modal-import-csv class-name="vendors" @imported="refetchData"/>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    // BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    // BTabs,
    // BTab,
    // BListGroup, BListGroupItem,
    // BCardFooter,
    // BCardBody,
    BFormCheckbox,
    // BFormSelect,
    // BLink,
    BFormInput,
    // BCardHeader,
    VBTooltip,
    VBModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useVendorList from './useVendorList'
import {avatarText} from '@core/utils/filter'
import {dictToSelectArray} from '@core/utils/utils'
import router from '@/router'
import Ripple from 'vue-ripple-directive'
import useAuth from '@/auth/useAuth'
import ModalImportCsv from "@/views/common/ModalImportCsv/ModalImportCsv.vue";

import {useUtils as useI18Utils} from "@core/libs/i18n";

const {i18n} = useI18Utils();

import VendorView from './VendorView.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BButton,
        BTable,
        // BAvatar,
        // BLink,
        BDropdown,
        BDropdownItem,
        BPagination,
        // BTabs,
        // BTab,
        // BListGroup,
        // BListGroupItem,
        // BCardFooter,
        // BCardBody,
        vSelect,
        BFormCheckbox,
        // BFormSelect,
        BLink,
        BFormInput,
        // BCardHeader,
        // BBadge,
        VendorView,
        ModalImportCsv,
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            selectedVendor: {},
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            vendorTypes: [],
            avalableFields: [
                {label: this.i18nT(`Type`), key: 'type', visible: true},
                {label: this.i18nT(`VAT number`), key: 'vat', visible: true},
                {label: this.i18nT(`Address`), key: 'address', visible: true},
                {label: this.i18nT(`Representative`), key: 'representative', visible: true},
            ],
            isActionsShow: false,
            software: useAuth.getCurrentSoftware(),
        }
    },
    created() {

        let storePreferences = useAuth.getColumnPreferences('VendorsTemplates');
          if(storePreferences) {
            this.avalableFields = storePreferences
        }

        this.vendorTypes.unshift({
            value: '',
            text: i18n(`All`)
        })

        this.$http.get(`system/vendorTypes`).then(({data}) => {
            this.vendorTypes.push(...dictToSelectArray(data.data))
        })

    },
    methods: {
        getTypeName(typeId) {
            const vendor = this.vendorTypes.find(type => type.value == typeId)
            return vendor ? vendor.text : ''
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onCopy(element) {
            this.$http
                .post(`documents/copyDocument?document_id=${element.item.Id}`)
                .then(() => {
                    this.refetchData()
                })
        },
        onDelete(vendor) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http.delete(`vendors?id=${vendor.item.Id}`).then((res) => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(res),
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })
                        this.refetchData()
                    }).catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        })
                    })
                }
            })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`vendors?id=${this.selectedItems.join(',')}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Deleted!`),
                                text: this.i18nT(`The vendors have been deleted.`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.Id)
            } else {
                this.selectedItems = []
            }
        },
        onExportExcel() {
        },
        onExportCsv() {
        },
        popupVendor(vendor) {
            this.selectedVendor = JSON.parse(JSON.stringify(vendor))
            this.selectedVendor.Type = this.getTypeName(this.selectedVendor.Type)
            //console.log(this.selectedVendor)
            this.$bvModal.show('modal-vendor')
        }
    },
    setup() {
        const {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            department,
            group,
            employees,
            vendorType,
            vendors,
            refetchData
        } = useVendorList(router.currentRoute.meta.template === 1)

        return {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            department,
            group,
            avatarText,
            employees,
            vendorType,
            vendors,
            refetchData
        }
    },
    computed: {
        computedColumns() {
            let col = [...this.tableColumns]
            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('VendorsTemplates', this.avalableFields);
            return col
        },
        emptyHtml() {
            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>" + this.i18nT(`No matching records found`) + "</h4>";
        },
    }
}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}

.ql-editor {
    min-height: 200px;
}

.tag-container {
    display: inline-block;
    background: #e6e6e6;
    color: #626262;
    padding: 3px 9px;
    font-size: 11px;
    font-weight: 600;
    margin: 4px;
    border-radius: 5px;

    .handler {
        cursor: pointer;
    }
}

.cv-card {
    min-height: 180px;

    span {
        font-size: 12px;
    }
}

.disabled {
    color: #6c757d;
}
</style>
