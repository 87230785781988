import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'
import {useUtils as useI18nUtils} from "@core/libs/i18n";
const { i18n } = useI18nUtils();
function i18nT(str){
  return i18n(str)
};

export default function useEmployeeList(isTemplate) {
  const refDocumentListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
    { key: 'name', label: i18nT(`Name`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1' },
    { key: 'type', label: i18nT(`Type`), sortable: true },
    { key: 'vat', label: i18nT(`VAT number`), sortable: true, thClass: 'sk-header-content' },
    { key: 'address', label: i18nT(`Address`), sortable: true },
    { key: 'representative',  label: i18nT(`Representative`), sortable: true },
    {
      key: 'action',
      label: i18nT(`Actions`),
      thClass: 'tbl-actions',
      tdClass: 'sticky-column',
      stickyColumn: true
    },
  ]
  const perPage = ref(10)
  const totalDocuments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const vendors = ref([])
  const vendorType = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refDocumentListTable.value
      ? refDocumentListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDocuments.value,
    }
  })

  const refetchData = () => {
    refDocumentListTable.value.refresh()
  }

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      vendorType,
    ],
    () => {
      refetchData()
    }
  )

  const fetchDocuments = (ctx, callback) => {
    const params = {
      page: currentPage.value,
      show_per_page: perPage.value,
      sort_by: sortBy.value,
      sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
      search: searchQuery.value
    }

    if (vendorType.value != null) {
      params['filters[type]'] = vendorType.value
    }

    axios
      .get(`vendors`, {
        params,
      })
      .then(({ data }) => {
        if (data.data.vendors) {
          vendors.value = data.data.vendors
          callback(data.data.vendors)
        } else {
          vendors.value = []
          callback([])
        }

        totalDocuments.value = data.data.pagination.records_count
      })
  }

  return {
    fetchDocuments,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDocumentListTable,
    totalDocuments,
    vendorType,
    vendors,
    refetchData,
  }
}
