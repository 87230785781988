<template>
  <div class="vendor-view">
    <b-row>
      <b-col cols="12">
        <h2 class="h1">{{ data.Label }}</h2>
        <h4 class="text-muted">{{ data.Type }}</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <div class="pt-2" v-if="data.BusinessPhone">
          <feather-icon icon="PhoneIcon"/>
          <span class="ml-1">{{ data.BusinessPhone }}</span>
        </div>
        <div>
          <feather-icon icon="MailIcon" v-if="data.BusinessEmail"/>
          <span class="ml-1">{{ data.BusinessEmail }}</span>
        </div>

        <h5 class="pt-2">{{ i18nT(`Company Information`) }}</h5>
        <div>
          <span class="h5">{{ i18nT(`UIC`) }}</span><span class="ml-1">{{ data.Number }}</span>
        </div>
        <div>
          <span class="h5">{{ i18nT(`VAT number`) }}</span><span class="ml-1">{{ data.VatNumber }}</span>
        </div>

        <h5 class="pt-2">{{ i18nT(`Address`) }}</h5>
        <p>{{ data.Address }}</p>

        <h5 class="pt-2">{{ i18nT(`Description`) }}</h5>
      </b-col>
      <b-col cols="6">

          <h4>{{ i18nT(`Contacts`) }}</h4>
          <b-row>
              <b-col cols="12">
                  <h5>{{ contact.Name }}</h5>
                  <div class="">
                      <feather-icon icon="MailIcon"/>
                      <span class="ml-1">{{ contact.Mail }}</span>
                  </div>
              </b-col>
          </b-row>

        <b-row v-for="contact in data.Contacts" :key="contact.Id" class="d-flex">
          <b-col cols="6">
            <h5>{{ contact.Name }}</h5>
            <div class="">
              <feather-icon icon="MailIcon"/>
              <span class="ml-1">{{ contact.Mail }}</span>
            </div>
          </b-col>
          <b-col cols="6">
            <h5>{{ contact.Relationship }}</h5>
            <div class="">
              <feather-icon icon="PhoneIcon"/>
              <span class="ml-1">{{ contact.Phone }}</span>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  }, 
}
</script>
